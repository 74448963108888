import React from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "JCI Nagpur Fortune",
    "url": "https://www.jcinagpurfortune.in/",
    "logo": "https://www.jcinagpurfortune.in/images/images/logo4.png",
    "image": "https://www.jcinagpurfortune.in/images/logo.png",
    "description": "Join JCI Nagpur Fortune to empower youth and create positive change in your community",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": "1475"
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Lower Ground Fortune Mall, behind Maharashtra bank, Sitabuldi, Nagpur",
      "addressLocality": "Nagpur",
      "addressRegion": "Maharashtra",
      "postalCode": "440012",
      "addressCountry": "India"
    },
    "sameAs": [
      "https://www.facebook.com/profile.php?id=61566611071468",
      "https://www.instagram.com/jcinagpurfortune/",
      "https://x.com/jcinagpufortune",
      "https://www.linkedin.com/in/jci-nagpur-fortune-601620330/"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+919975288300",
      "contactType": "customer support"
    }
  };
// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';






function Index()
{
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-XQGMYG40J6', {
      page_path: location.pathname,
    });
  }, [location])





  
  return (

    <>


    

<Helmet>
        <title>JCI Nagpur Fortune - Youth Empowerment Organization</title>
        <meta name="description" content="Join JCI Nagpur Fortune to empower youth and create positive change in your community." />
        <meta name="keywords" content="JCI India, youth empowerment, leadership, community service,JCI Nagpur Fortune,Nagpur,developmet,growth,Non-profit Organization,Maharashtra,India,Best Organization in Nagpur,Fortune,sitaburdi" />
        <link rel="canonical" href="https://www.jcinagpurfortune.in/" />
      </Helmet>

    <div>
      {/* <!--Main Slider--> */}
      {/* <!--Main Slider--> */}
      <div id="demo" className="carousel slide p-3" data-bs-ride="carousel">
        {/* Indicators/dots */}
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="1"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="2"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="3"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="4"
          ></button>



<button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="5"
          ></button>



<button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="6"
          ></button>




<button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="7"
          ></button>



<button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="8"
          ></button>


<button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="9"
          ></button>


        </div>

        {/* The slideshow/carousel */}
        <div className="carousel-inner">




        <div className="carousel-item active">
            <img
              src="images/main-slider/7.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>




          <div className="carousel-item">
            <img
              src="images/main-slider/6.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>



          <div className="carousel-item">
            <img
              src="images/main-slider/5.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>



          <div className="carousel-item">
            <img
              src="images/main-slider/8.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>





          <div className="carousel-item">
            <img
              src="images/main-slider/9.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>











          <div className="carousel-item">
            <img
              src="images/main-slider/1.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>









          <div className="carousel-item">
            <img
              src="images/main-slider/2.webp"
              alt="JCI Zone 9 Dimond Zonecon 2024 "
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <img
              src="images/main-slider/3.webp"
              alt="JCI Speech Craft 2024"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* Caption content */}
              </div>
            </div>
          </div>

          {/* New Images */}
          <div className="carousel-item">
            <img
              src="images/main-slider/4.webp"
              alt="CAPP Training"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                {/* <h3>San Francisco</h3>
              <p>Exploring the Golden Gate!</p> */}
              </div>
            </div>
          </div>

          {/* <div className="carousel-item">
            <img
              src="images/main-slider/5.png"
              alt="Miami"
              className="d-block carousel-image"
              style={{ width: "100%" }}
            />
            <div
              className="carousel-caption d-flex align-items-center"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "0",
                transform: "translateY(50%)",
              }}
            >
              <div className="text-start" style={{ paddingLeft: "15px" }}>
                <h3>Miami</h3>
              <p>Sunshine and beaches!</p>
              </div>
            </div>
          </div> */}
        </div>

        {/* Left and right controls/icons */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#demo"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#demo"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </button>
      </div>
      
      {/* <div className="container-fluid mt-3">  
<h3>Carousel Example</h3>  
<p>The following example shows how to create a basic carousel with indicators and controls.</p>  
</div>   */}
     
      {/* <!--End Main Slider--> */}
      {/* <!--End Main Slider--> */}
      {/* <!-- Call To Action Section --> */}
      {/* <section class="call-to-action-section">
        <div class="auto-container">
          <div class="clearfix">
            <div class="pull-left">
              <h2>We develop leaders for a better tomorrow</h2>
             
            </div>
            <div class="pull-right">
              <a href="/contact" class="theme-btn btn-style-two"style={{textDecoration:"none"}}>
                Join Now
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Call To Action Section --> */}
      {/* <!-- Goal Section --> */}
      <section
        class="goal-section"
        style={{ backgroundImage: "url(images/background/2.jpg )" }}
      >
        <div class="auto-container">
          {/* <!-- Sec Title --> */}

          <div class="sec-title centered">
            {/* <!-- <h2><span class="theme_color">Mission & </span> Goals</h2> --> */}

            <div class="text"><h1>Welcome To JCI Nagpur Fortune</h1></div>
            <h2>
              <span class="theme_color">Mission & </span>{" "}
              <span style={{ color: "#f2ad45" }}>Goals</span>
            </h2>
          </div>

          <div class="row clearfix">
            {/* <!-- Goal Block --> */}

            <div class="goal-block col-lg-4 col-md-6 col-sm-12">
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="hover-one"></div>

                <div class="hover-two"></div>

                <div class="icon-box">
                  <span class="icon flaticon-donation-1"></span>
                </div>

                <h3>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Why JCI
                  </a>
                </h3>

                <div class="text">
                  Junior Chamber International (JCI) is a worldwide federation
                  of young leaders and entrepreneurs.
                </div>
              </div>
            </div>

            {/* <!-- Goal Block --> */}

            <div class="goal-block col-lg-4 col-md-6 col-sm-12">
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div class="hover-one"></div>

                <div class="hover-two"></div>

                <div class="icon-box">
                  <span class="icon flaticon-donation-2"></span>
                </div>

                <h3>
                  <a href="#" style={{ textDecoration: "none" }}>
                    JCI Vision
                  </a>
                </h3>

                <div class="text">
                  To be the leading global network of Young Active Citizens.
                </div>
              </div>
            </div>

            {/* <!-- Goal Block --> */}

            <div class="goal-block col-lg-4 col-md-6 col-sm-12">
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div class="hover-one"></div>

                <div class="hover-two"></div>

                <div class="icon-box">
                  <span class="icon flaticon-house-1"></span>
                </div>

                <h3>
                  <a href="#" style={{ textDecoration: "none" }}>
                    JCI Mission
                  </a>
                </h3>

                <div class="text">
                  To Provide Development Opportunities that empower young people
                  to create positive changes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 	
	<!-- Welcome Section --> */}
      <section class="welcome-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Content Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <h2>
                  What is <span class="theme_color">JCI</span>
                </h2>
                <div class="bold-text">
                  Developing Leaders for a Changing World
                </div>
                <div class="text">
                  Junior Chamber International (JCI) is a worldwide federation
                  of young leaders and entrepreneurs with nearly five lakh
                  active members and millions of alumni spread across more than
                  115 countries. We are young active citizens all over the
                  world, living, communicating, taking action and creating
                  impact in our communities.
                </div>

                <a
                  href="/aboutjci"
                  class="theme-btn btn"
                  style={{
                    textDecoration: "none",
                    backgroundColor: "#0074D9",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Know more
                </a>
                <br />
                <br />
                <br />
              </div>
            </div>

            {/* <!-- Video Column --> */}
            <div class="video-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                {/* <!--Video Box--> */}
                <div
                  class="video-box wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <figure class="video-image">
                    <img src="images/resource/video-img.jpeg" alt="JCI India" />
                  </figure>
                  <a
                    href="https://www.youtube.com/watch?v=unZPbEp17sY"
                    class="lightbox-image overlay-box"
                  >
                    <span class="flaticon-play-button">
                      <i class="ripple"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Welcome Section -->
	
	<!-- Counter Section --> */}
      <section
        class="counter-section"
        style={{ backgroundImage: "url(images/background/1.jpg)" , alt:"jcinagpurfortune" }}
      >
        <div class="auto-container">
          {/* <!-- Fact Counter --> */}
          <div class="fact-counter">
            <div class="row clearfix">
              {/* <!--Column--> */}
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="icon flaticon-lightbulb"></div>
                    <div class="count-outer count-box">
                      <span
                        class="count-text"
                        data-speed="2500"
                        data-stop="3586"
                      >
                        10
                      </span>
                    </div>
                    <h4 class="counter-title">Donator</h4>
                  </div>
                </div>
              </div>

              {/* <!--Column--> */}
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="icon flaticon-startup-1"></div>
                    <div class="count-outer count-box alternate">
                      <span
                        class="count-text"
                        data-speed="5000"
                        data-stop="95580"
                      >
                        5
                      </span>
                      +
                    </div>
                    <h4 class="counter-title">Fundrising</h4>
                  </div>
                </div>
              </div>

              {/* <!--Column--> */}
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="icon flaticon-process"></div>
                    <div class="count-outer count-box">
                      <span
                        class="count-text"
                        data-speed="2000"
                        data-stop="640"
                      >
                        30
                      </span>
                      +
                    </div>
                    <h4 class="counter-title">Volunteer</h4>
                  </div>
                </div>
              </div>

              {/* <!--Column--> */}
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="900ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="icon flaticon-sketch"></div>
                    <div class="count-outer count-box">
                      <span
                        class="count-text"
                        data-speed="2500"
                        data-stop="1980"
                      >
                        0
                      </span>
                    </div>
                    <h4 class="counter-title">Complete Projects</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Counter Section -->
	
	<!-- Causes Section -->
	
	<!-- End Causes Section -->
	
	<!-- Services Section --> */}
      <section
        class="services-section"
        style={{ backgroundImage: "url(images/background/2.jpg)" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Service Block --> */}
            <div class="service-block col-lg-4 col-md-6 col-sm-12">
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="icon-box">
                  <span class="icon flaticon-donation-1"></span>
                </div>
                <h3>
                  <a>Leadership Organization</a>
                </h3>
                <div class="text">We develop leaders for a better tomorrow</div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div class="service-block col-lg-4 col-md-6 col-sm-12">
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div class="icon-box">
                  <span class="icon flaticon-money-bag"></span>
                </div>
                <h3>
                  <a>Sustainable Solutions</a>
                </h3>
                <div class="text">
                  Our members provide sustainable solutions in their region
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div class="service-block col-lg-4 col-md-6 col-sm-12">
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div class="icon-box">
                  <span class="icon flaticon-heart-3"></span>
                </div>
                <h3>
                  <a>Young active leaders</a>
                </h3>
                <div class="text">
                  We are the largest organization with young and active leaders
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section -->








	
	<!-- Fluid Section One --> */}
      <section
        class="popular-causes-section"
        style={{ backgroundImage: "url(images/background/10.jpg)", alt:"jcinagpurfortune" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Image Column --> */}

            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div
                class="inner-column wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="image">
                  <img src="images/resource/12.jpeg" alt="Mr.Prashant Kadhao" />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}

            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                {/* <!-- Sec Title --> */}

                <div class="sec-title light">
                  <h2>
                    <span class="theme_color">Mr.Prashant Kadhao</span>
                  </h2>
                  <div class="text">
                    <ul>
                      <li>
                        <h4>Founder President Of JCI Nagpur Fortune</h4>
                      </li>
                      <li>
                        <h4>
                          Founder and Director Of PSK Technologies Pvt.LTD IT
                          Company
                        </h4>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <div class="bold-text">Food For Poor Peoples</div> */}

                <div class="text">
                  <p align="justify">
                    With over 17+ years of experience in the IT field and 13+
                    years of successfully running his own company, Prashant is a
                    expertize and certified in LINUX (RHCSA/RHEL), Microsoft
                    technologies, and AWS Solutions,Computer solution and
                    infrastructure. He has completed over 500 projects and
                    facilitated the placement of more than 2000 interns in
                    leading companies, showcasing his commitment to talent
                    development. Prashant holds an MBA in Marketing and is a
                    certified professional with credentials including BIZ XI,
                    Speech Craft 2024, AOS 2024, EPS, and more. As a public
                    speaker and self-mastery coach, he empowers individuals and
                    supports interns in startups, focusing on personal and
                    professional growth through mindset shifts and holistic
                    well-being. In 2023, Prashant joined JCI, where he quickly
                    rose to prominence, serving as VPPR and later as VPBO in
                    2024. On September 25, 2024, he founded JCI Nagpur Fortune,
                    where he currently serves as the Founder President,
                    continuing his mission to inspire youth.
                  </p>
                </div>

                {/* <div class="donate-percentage"> */}

                {/* <div class="donate-bar">

								<div class="bar-inner"><div class="bar progress-line" data-width="75"><div class="count-box"><span class="count-text" data-speed="2500" data-stop="75">0</span>%</div></div></div>

							</div> */}

                {/* <div class="donate-bar-info">

								<div class="donate-percent"></div>

							</div> */}

                {/* <div class="amounts clearfix">

								<div class="pull-left number">Raised: $7,500</div>

								<div class="pull-right number">Goal: $10,000</div>

							</div> */}

                {/* </div> */}
                {/* 
						<div class="btns-box">

							<a href="donate.html" class="theme-btn btn-style-five">Donate Now</a>

							<a href="about.html" class="theme-btn btn-style-two">More Detail</a>

						</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 	
	<!-- Call To Action Section --> */}
      <section
        class="call-to-action-section-two"
        style={{ backgroundImage: "url(images/background/7.jpg)", alt:"jcinagpurfortune" }}
      >
        <div class="auto-container">
          <h2>What we do</h2>
          <div class="text">
            We develop skills, knowledge and leadership abilities of young
            citizens who go on to provide sustainable solutions that creates a
            positive impact
          </div>
          <a
            href="/contact"
            class="theme-btn btn-style-five"
            style={{ textDecoration: "none", color: "white" }}
          >
            <strong> Join Us Now</strong>
          </a>
        </div>
      </section>
      {/* <!-- Call To Action Section -->		 */}
      {/* <!-- Volunter Section -->
	
	<!-- End Volunter Section --7
	
	<!-- News Section -->
	
	<!-- End News Section -->
	
	<!--Clients Section-->
    
    <!--End Clients Section-->
	
	<!--Main Footer-->
    */}
      {/* <!--End pagewrapper


<!--Scroll to top--> */}
      <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="fa fa-arrow-up"></span>
      </div>
      {/* <!-- Color Palate / Color Switcher --> */}
      <div class="color-palate">
        {/* <div class="color-trigger">
        <i class="fa fa-paint-brush"></i>
    </div> */}
        <div class="color-palate-head">
          <h1>Choose Your Color</h1>
        </div>
        <div class="various-color clearfix">
          <div class="colors-list">
            <span
              class="palate default-color active"
              data-theme-file="css/color-themes/default-theme.css"
            ></span>
            <span
              class="palate green-color"
              data-theme-file="css/color-themes/green-theme.css"
            ></span>
            <span
              class="palate blue-color"
              data-theme-file="css/color-themes/blue-theme.css"
            ></span>
            <span
              class="palate orange-color"
              data-theme-file="css/color-themes/orange-theme.css"
            ></span>
            <span
              class="palate purple-color"
              data-theme-file="css/color-themes/purple-theme.css"
            ></span>
            <span
              class="palate teal-color"
              data-theme-file="css/color-themes/teal-theme.css"
            ></span>
            <span
              class="palate brown-color"
              data-theme-file="css/color-themes/brown-theme.css"
            ></span>
            <span
              class="palate yellow-color"
              data-theme-file="css/color-themes/yellow-color.css"
            ></span>
          </div>
        </div>

        <ul class="box-version option-box">
          {" "}
          <li class="box">Boxed</li> <li>Full width</li>
        </ul>
        <ul class="rtl-version option-box">
          {" "}
          <li class="rtl">RTL Version</li> <li>LTR Version</li>{" "}
        </ul>

        <a href="#" class="purchase-btn">
          Purchase now $17
        </a>

        <div class="palate-foo">
          <span>
            You will find much more options for colors and styling in admin
            panel. This color picker is used only for demonstation purposes.
          </span>
        </div>
      </div>
      {/* <!-- sidebar cart item --> */}
      <div class="xs-sidebar-group info-group">
        <div class="xs-overlay xs-bg-black"></div>
        <div class="xs-sidebar-widget">
          <div class="sidebar-widget-container">
            <div class="widget-heading">
              <a href="#" class="close-side-widget"></a>
            </div>
            <div class="sidebar-textwidget">
              {/* <!-- Sidebar Info Content --> */}
              <div class="sidebar-info-contents">
                <div class="content-inner">
                  <div class="logo">
                    <a href="/">
                      <img src="images/logo-2.png" alt="JCI Nagpur Fortune" />
                    </a>
                  </div>
                  <div class="content-box">
                    <h2>About Us</h2>
                    <p class="text">
                      Core values are the fundamental beliefs of a person or
                      organization. The core values are the guiding prin ples
                      that dictate behavior and action suas labore saperet has
                      there any quote for write lorem percit latineu.
                    </p>
                    <a href="#" class="theme-btn btn-style-three">
                      <i>Consultation</i>
                    </a>
                  </div>
                  <div class="contact-info">
                    <h2>Contact Info</h2>
                    <ul class="list-style-one">
                      <li>
                        <span class="icon flaticon-map-1"></span>Chicago 12,
                        Melborne City, USA
                      </li>
                      <li>
                        <span class="icon flaticon-telephone"></span>(111)
                        111-111-1111
                      </li>
                      <li>
                        <span class="icon flaticon-message-1"></span>
                        khidmat@gmail.com
                      </li>
                      <li>
                        <span class="icon flaticon-timetable"></span>Week Days:
                        09.00 to 18.00 Sunday: Closed
                      </li>
                    </ul>
                  </div>
                  {/* <!-- Social Box --> */}
                  <ul class="social-box">
                    <li class="facebook">
                      <a href="#" class="fab fa-facebook-f"></a>
                    </li>
                    <li class="twitter">
                      <a href="#" class="fab fa-twitter"></a>
                    </li>
                    <li class="linkedin">
                      <a href="#" class="fab fa-linkedin-in"></a>
                    </li>
                    <li class="instagram">
                      <a href="#" class="fab fa-instagram"></a>
                    </li>
                    <li class="youtube">
                      <a href="#" class="fab fa-youtube"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END sidebar widget item -->

<!-- xs modal --> */}
      <div
        class="zoom-anim-dialog mfp-hide modal-searchPanel"
        id="modal-popup-2"
      >
        <div class="xs-search-panel">
          <form action="#" method="POST" class="xs-search-group">
            <input
              type="search"
              class="form-control"
              name="search"
              id="search"
              placeholder="Search"
            />
            <button type="submit" class="search-button">
              <i class="icon icon-search"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
    </>
  );
}

export default Index;
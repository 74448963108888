import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const schemaMarkup = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "JCI Nagpur Fortune",
  "url": "https://www.jcinagpurfortune.in/",
  "logo": "https://www.jcinagpurfortune.in/images/images/logo4.webp",
  "image": "https://www.jcinagpurfortune.in/images/logo.webp",
  "description": "Join JCI Nagpur Fortune to empower youth and create positive change in your community",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "1475"
  },
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Lower Ground Fortune Mall, behind Maharashtra bank, Sitabuldi, Nagpur",
    "addressLocality": "Nagpur",
    "addressRegion": "Maharashtra",
    "postalCode": "440012",
    "addressCountry": "India"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61566611071468",
    "https://www.instagram.com/jcinagpurfortune/",
    "https://x.com/jcinagpufortune",
    "https://www.linkedin.com/in/jci-nagpur-fortune-601620330/"
  ],
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+919975288300",
    "contactType": "customer support"
  }
};


function OurTeam() {
  const location = useLocation();
  useEffect(() => {
    window.gtag('config', 'G-XQGMYG40J6', {
      page_path: location.pathname,
    });
  }, [location]);
  return (
<>
<Helmet>
        <title>Meet Our Team - JCI Nagpur Fortune Leadership</title>
        <meta name="description" content="Meet the dedicated team behind JCI Nagpur Fortune. Our passionate leaders work tirelessly to empower youth and drive positive change in communities.." />
        <meta name="keywords" content="JCI India, youth empowerment, leadership, community service,JCI Nagpur Fortune,Nagpur,developmet,growth,Non-profit Organization,Maharashtra,India,Best Organization in Nagpur,Fortune,sitaburdi" />
        <link rel="canonical" href="http://www.jcinagpurfortune.in/gallery" />
      </Helmet>

    <div>
      {/* <!--Page Title--> */}
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/12.jpg)" , alt:"jcinagpurfortune"}}
      >
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!--Title --> */}
            <div className="title-column col-lg-6 col-md-12 col-sm-12">
              <h1>Our Team</h1>
            </div>
            {/* <!--Bread Crumb --> */}
            <div className="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
              <ul className="bread-crumb clearfix">
                <li>
                  <a href="/">
                    <span className="icon fas fa-home"></span> Home
                  </a>
                </li>
                <li className="active">
                  <span className="icon fas fa-arrow-alt-circle-right"></span>{" "}
                  OurTeam
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      {/* <!-- Volunter Section --> */}
      <section class="volunter-section team-page-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- prashant kadhao Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/prashant kadhao.webp" alt="JC Prashant Kadhao" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Prashant Kadhao</a>
                  </h3>
                  <div class="designation">FOUNDER PRESIDENT  </div>
                </div>
              </div>
            </div>

            {/* <!-- Gayatri Kadhao Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/gayatri kadhao (2).webp" alt="JC Gayatri Kadhao" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Gayatri Kadhao</a>
                  </h3>
                  <div class="designation">Secretory</div>
                </div>
              </div>
            </div>

            {/* <!-- Abhishek Tumsare Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/abhishekh tumsare.webp" alt="JC Abhishek Tumsare" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC ABHISHEK TUMSARE</a>
                  </h3>
                  <div class="designation">TREASURER </div>
                </div>
              </div>
            </div>

            {/* <!-- Sharda Waghmare Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sharda.png" alt="JC Sharda Waghmare" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Sharda Waghmare</a>
                  </h3>
                  <div class="designation">VPMO </div>
                </div>
              </div>
            </div>

            {/* <!-- ADITYA SUKHDEVE  Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Aditya.webp" alt="JC ADITYA SUKHDEVE" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC ADITYA SUKHDEVE </a>
                  </h3>
                  <div class="designation">VPCO </div>
                </div>
              </div>
            </div>

            {/* <!-- JC SHRILEKH SHRIKHANDE  Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Shrilekh  shrikhande.webp" alt="Jc shrilekh Shrikhande" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SHRILEKH SHRIKHANDE</a>
                  </h3>
                  <div class="designation">VPG&D </div>
                </div>
              </div>
            </div>

            {/* <!-- . JC MANSI BOPCHE    Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/mansi bopche.webp" alt="JC MANSI BOPCHE " />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC MANSI BOPCHE   </a>
                  </h3>
                  <div class="designation">VPPR</div>
                </div>
              </div>
            </div>

            {/* <!-- Anam Mansuri Block --> */}
           

            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Anam.webp" alt="JC ANAM MANSURI " />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Anam Mansuri  </a>
                  </h3>
                  <div class="designation">VPTR</div>
                </div>
              </div>
            </div>












            {/* <!-- JC AMOD  CHAUDHARI  Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Amod (2).png" alt="JC AMOD  CHAUDHARI" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        <strong>Connect with them</strong>
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC AMOD CHAUDHARI </a>
                  </h3>
                  <div class="designation">VPBO</div>
                </div>
              </div>
            </div>
            {/* <!-- JC SANJANA KASHIMKAR   Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sanjana kashimkar.webp" alt="JC SANJANA KASHIMKAR" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SANJANA KASHIMKAR  </a>
                  </h3>
                  <div class="designation">VPIN </div>
                </div>
              </div>
            </div>
            {/* <!-- JC ASHWINI THAKARE   Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Ashwini thakare.webp" alt="JC ASHWINI THAKARE" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Ashwini Thakare  </a>
                  </h3>
                  <div class="designation">DITR </div>
                </div>
              </div>
            </div>

            {/* <!-- JC NISHANT MASKE  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/nishant.png" alt="JC NISHANT MASKE" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a>JC NISHANT MASKE </a>
                  </h3>
                  <div class="designation">DIG&D </div>
                </div>
              </div>
            </div>
            {/* <!-- JC SHUBHANGI TIJARE --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/shubhangi.webp" alt="JC SHUBHANGI TIJARE" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SHUBHANGI TIJARE</a>
                  </h3>
                  <div class="designation">DICO</div>
                </div>
              </div>
            </div>

            {/* <!--  JC SHUBHANGI  TIJARE  --> */}
            {/* <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/shubhangi.webp" alt="JC SHUBHANGI  TIJARE" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SHUBHANGI TIJARE </a>
                  </h3>
                  <div class="designation">DIIN </div>
                </div>
              </div>
            </div> */}

            {/* <!--  JC KHUSHI  DIWAN   --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Khushi.webp" alt="JC KHUSHI  DIWAN" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC KHUSHI DIWAN </a>
                  </h3>
                  <div class="designation">DIMO  </div>
                </div>
              </div>
            </div>


            {/* <!--  JC DEVANSHU GOTE   --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/DEVANSHU GOTE.webp" alt="JC DEVANSHU GOTE" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC DEVANSHU GOTE   </a>
                  </h3>
                  <div class="designation">DIBO</div>
                </div>
              </div>
            </div>

            {/* <!--  JC sonalli rokade  --> */}
            {/* <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sonali.webp" alt="JC sonalli rokade" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SONALI ROKDE </a>
                  </h3>
                  <div class="designation">DIPR </div>
                </div>
              </div>
            </div> */}

            {/* <!--  JC Abhimanyu Mishra  --> */}
            {/* <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Abhimanyu Mishra.webp" alt="JC Abhimanyu Mishra " />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC ABHIMANYU MISHRA</a>
                  </h3>
                  <div class="designation">CO BULLETIN EDITOR  </div>
                </div>
              </div>
            </div> */}

            {/* <!--  JC sarthak Jichkar  --> */}
            {/* <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sarthak Jichkar.webp" alt=" JC sarthak Jichkar " />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SARTHAK JICHKAR </a>
                  </h3>
                  <div class="designation">BULLETIN EDITOR   </div>
                </div>
              </div>
            </div> */}

            {/* <!--  JC tanushri dhote  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/tanushri.webp" alt="JC tanushri dhote" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC TANUSHRI DHOTE  </a>
                  </h3>
                  <div class="designation">DIRECTOR LADY JAYCEE   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End team Section --> */}
    </div>
    </>
  )
}

export default OurTeam

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, CardImg, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader } from 'reactstrap';
import { FaSearchPlus } from 'react-icons/fa'; // Import a zoom icon
import '../Events/EventImageComponent.css'; // Import custom CSS for hover effect
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const schemaMarkup = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "JCI Nagpur Fortune",
  "url": "https://www.jcinagpurfortune.in/",
  "logo": "https://www.jcinagpurfortune.in/images/images/logo4.webp",
  "image": "https://www.jcinagpurfortune.in/images/logo.webp",
  "description": "Join JCI Nagpur Fortune to empower youth and create positive change in your community",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "1475"
  },
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Lower Ground Fortune Mall, behind Maharashtra bank, Sitabuldi, Nagpur",
    "addressLocality": "Nagpur",
    "addressRegion": "Maharashtra",
    "postalCode": "440012",
    "addressCountry": "India"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61566611071468",
    "https://www.instagram.com/jcinagpurfortune/",
    "https://x.com/jcinagpufortune",
    "https://www.linkedin.com/in/jci-nagpur-fortune-601620330/"
  ],
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+919975288300",
    "contactType": "customer support"
  }
};



<Helmet>
<title> JCI Nagpur Fortune Events and Activities</title>
<meta name="description" content="Explore our gallery showcasing JCI Nagpur Fortune events, community service activities, and youth leadership initiatives. Witness the impact we create together!." />
<meta name="keywords" content="JCI India, youth empowerment, leadership, community service,JCI Nagpur Fortune,Nagpur,developmet,growth,Non-profit Organization,Maharashtra,India,Best Organization in Nagpur,Fortune,sitaburdi" />
<link rel="canonical" href="http://www.jcinagpurfortune.in/gallery" />
</Helmet>

const ImageTabs = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.gtag('config', 'G-XQGMYG40J6', {
      page_path: location.pathname,
    });
  }, [location]);

  const images = {
    all: [


      { src: 'images/eventimages/27.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },



      { src: 'images/eventimages/28.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/29.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/30.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/31.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/32.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/33.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/34.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },

      { src: 'images/eventimages/26.webp', alt: 'Training On stress Relief Strategies:Finding Calm In Chaos', text: 'Training On stress Relief Strategies:Finding Calm In Chaos' },
      { src: 'images/eventimages/25.webp', alt: 'Effective Public speaking Training Successfully Done By JCI Nagpur Fortune', text: 'Effective Public speaking Training Successfully Done By JCI Nagpur Fortune' },
      { src: 'images/eventimages/1.webp', alt: 'JCI Nagpur Fortune Orientation', text: 'JCI Nagpur Fortune Orientation' },
      { src: 'images/eventimages/2.webp', alt: '28 & 29 September 2024 JCI Zone 9 Dimond Zonecon 2024 Successfully Done ', text: '28 & 29 September 2024 JCI Zone 9 Dimond Zonecon 2024 Successfully Done         ' },
      { src: 'images/eventimages/3.webp', alt: '28 & 29 September 2024 JCI Zone 9 Dimond Zonecon 2024 Successfully Done ', text: '28 & 29 September 2024 JCI Zone 9 Dimond Zonecon 2024 Successfully Done !' },
      { src: 'images/eventimages/4.webp', alt: 'MIDCON 2024', text: 'MIDCON 2024' },
      { src: 'images/eventimages/5.webp', alt: 'Biz-9 - 2024  Hosted by JCI Raipur Metro Successfully Done', text: 'Biz-9 - 2024  Hosted by JCI Raipur Metro Successfully Done' },
      { src: 'images/eventimages/6.webp', alt: 'Biz-9 - 2024  Hosted by JCI Raipur Metro Successfully Done', text: 'Biz-9 - 2024  Hosted by JCI Raipur Metro Successfully Done' },







      { src: 'images/eventimages/18.webp', alt: 'Blood Donation Camp', text: 'Blood Donation Camp' },
      { src: 'images/eventimages/19.webp', alt: 'Yoga Training', text: 'Yoga Training ' },
      { src: 'images/eventimages/20.webp', alt: 'Corporate Training', text: 'Corporate Training' },
      { src: 'images/eventimages/21.webp', alt: 'Corporate Training', text: 'Corporate Training' },
      { src: 'images/eventimages/22.webp', alt: 'Corporate Training(IPO Individual  Peformance Outcome)', text: 'Corporate Training(IPO Individual  Peformance Outcome) ' },
      { src: 'images/eventimages/23.webp', alt: 'Corporate Training(Is Your Mind Hijacked)', text: 'Corporate Training(Is Your Mind Hijacked) ' },
      { src: 'images/eventimages/24.webp', alt: 'Corporate Training(Change begins with me)', text: 'Corporate Training(Change begins with me) ' },






      { src: 'images/eventimages/7.webp', alt: 'CAPP Training', text: 'CAPP Training' },
      { src: 'images/eventimages/8.webp', alt: 'CAPP Training', text: 'CAPP Training         ' },
      { src: 'images/eventimages/9.webp', alt: 'CAPP Training', text: 'CAPP Training' },
      { src: 'images/eventimages/10.webp', alt: 'Nagpur JCOM TABLE 2.0', text: 'Nagpur JCOM TABLE 2.0' },
      { src: 'images/eventimages/11.webp', alt: 'JCI Speech Craft 2024', text: 'JCI Speech Craft 2024 ' },
      { src: 'images/eventimages/12.webp', alt: 'JCI Speech Craft 2024', text: 'JCI Speech Craft 2024 ' },









      { src: 'images/eventimages/13.webp', alt: 'JC Kohei Oya from JAPAN Multi LO JCI Vice President Visit At Nagpur !', text: 'JC Kohei Oya from JAPAN Multi LO JCI Vice President Visit At Nagpur !' },
      { src: 'images/eventimages/14.webp', alt: 'JCOM Business Meeting  ', text: 'JCOM Business Meeting         ' },
      { src: 'images/eventimages/15.webp', alt: 'AOS Training', text: 'AOS Training' },
      { src: 'images/eventimages/16.webp', alt: 'JCI LO Officer Training  Seminar', text: 'JCI LO Officer Training  Seminar' },
      { src: 'images/eventimages/17.webp', alt: 'Be leader ! Make Leaders ! Empower people ', text: 'Be leader ! Make Leaders ! Empower people  ' },
      // { src: 'images/eventimages/12.webp', alt: 'Image 6', text: 'JCI Speech Craft 2024 ' },

    ],
    
    used: [

 { src: 'images/eventimages/26.webp', alt: 'Training On stress Relief Strategies:Finding Calm In Chaos', text: 'Training On stress Relief Strategies:Finding Calm In Chaos' },
      { src: 'images/eventimages/25.webp', alt: 'Effective Public speaking Training Successfully Done By JCI Nagpur Fortune', text: 'Effective Public speaking Training Successfully Done By JCI Nagpur Fortune' },
      { src: 'images/eventimages/19.webp', alt: 'Yoga Training', text: 'Yoga Training ' },
      { src: 'images/eventimages/20.webp', alt: 'Corporate Training', text: 'Corporate Training' },
      { src: 'images/eventimages/21.webp', alt: 'Corporate Training', text: 'Corporate Training' },
      { src: 'images/eventimages/22.webp', alt: 'Corporate Training(IPO Individual  Peformance Outcome)', text: 'Corporate Training(IPO Individual  Peformance Outcome) ' },
      { src: 'images/eventimages/23.webp', alt: 'Corporate Training(Is Your Mind Hijacked)', text: 'Corporate Training(Is Your Mind Hijacked) ' },
      { src: 'images/eventimages/24.webp', alt: 'Corporate Training(Change begins with me)', text: 'Corporate Training(Change begins with me) ' },
      { src: 'images/eventimages/15.webp', alt: 'AOS Training', text: 'AOS Training' },
      { src: 'images/eventimages/16.webp', alt: 'JCI LO Officer Training  Seminar', text: 'JCI LO Officer Training  Seminar' },
      { src: 'images/eventimages/7.webp', alt: 'CAPP Training', text: 'CAPP Training' },
      { src: 'images/eventimages/8.webp', alt: 'CAPP Training', text: 'CAPP Training         ' },
      { src: 'images/eventimages/9.webp', alt: 'CAPP Training', text: 'CAPP Training' },
      

    
    ],


    FirstInstallationCeremony:[


      { src: 'images/eventimages/27.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },



      { src: 'images/eventimages/28.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/29.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/30.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/31.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/32.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/33.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },
      { src: 'images/eventimages/34.webp', alt: 'MIDCON 2024', text: 'JCI Nagpur Fortune First Installation Ceremony' },

    ],


    new: [
      { src: 'images/eventimages/18.webp', alt: 'Blood Donation Camp', text: 'Blood Donation Camp' },
    ],


    clearance: [
      { src: 'images/eventimages/1.webp', alt: 'JCI Nagpur Fortune Orientation', text: 'JCI Nagpur Fortune Orientation' },
    
    ],
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = () => setModal(!modal);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    toggleModal();
  };

  return (
<>
       {/* <!--Page Title--> */}
       <section class="page-title" style={{backgroundImage:'url(images/background/12.jpg) ' , alt:"jcinagpurfortune"}}>
       <div class="auto-container">
           <div class="row clearfix">
               {/* <!--Title --> */}
               <div class="title-column col-lg-6 col-md-12 col-sm-12">
                   <h1>Gallery</h1>
                 </div>
                 {/* <!--Bread Crumb --> */}
                 <div class="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
                     <ul class="bread-crumb clearfix">
                         <li><a href="/"><span class="icon fas fa-home"></span> Home</a></li>
                         <li class="active"><span class="icon fas fa-arrow-alt-circle-right"></span> Gallery</li>
                     </ul>
                 </div>
             </div>
         </div>
     </section>
     {/* <!--End Page Title--> */}
     <br/>
    <div className="container">
      <Nav tabs className="justify-content-center">
        <NavItem>
          <NavLink className={activeTab === 'all' ? 'active' : ''} onClick={() => toggleTab('all')}>
          <strong>All</strong>  
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab === 'used' ? 'active' : ''} onClick={() => toggleTab('used')}>
            <strong>Training</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab === 'new' ? 'active' : ''} onClick={() => toggleTab('new')}>
            <strong>Community</strong>
          </NavLink>
        </NavItem>




        <NavItem>
          <NavLink className={activeTab === 'new' ? 'active' : ''} onClick={() => toggleTab('new')}>
            <strong>Management</strong>
          </NavLink>
        </NavItem>






        <NavItem>
          <NavLink className={activeTab === 'clearance' ? 'active' : ''} onClick={() => toggleTab('clearance')}>
           <strong>Growth & Development</strong> 
           </NavLink>
        </NavItem>



        <NavItem>
          <NavLink className={activeTab === 'FirstInstallationCeremony' ? 'active' : ''} onClick={() => toggleTab('FirstInstallationCeremony')}>
           <strong>First Installation Ceremony</strong> 
           </NavLink>
        </NavItem>


      </Nav>
      <br/>
      <TabContent activeTab={activeTab}>
        {Object.keys(images).map((tab) => (
          <TabPane tabId={tab} key={tab}>
            <div className="row">
              {images[tab].map((image, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <Card className="position-relative" onClick={() => handleImageClick(image)}>
                    <CardImg
                      top
                      src={image.src}
                      alt={image.alt}
                      style={{ cursor: 'pointer' }}
                    />
                    <div className="zoom-icon">
                      <FaSearchPlus />
                    </div>
                    <CardBody className="text-center">
                      <CardTitle>
                        {image.text}
                      </CardTitle>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </TabPane>
        ))}
      </TabContent>

      {/* Modal for displaying the zoomed image */}
      <Modal isOpen={modal} toggle={toggleModal} centered className="modal-lg">
        <ModalHeader toggle={toggleModal} className="border-0">
          {/* Only show dismiss icon, you can customize this further */}
          <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        {selectedImage && (
          <img src={selectedImage.src} alt={selectedImage.alt} className="img-fluid w-100"  />
        )}
      </Modal>
    </div>
    </>
  );
};

export default ImageTabs;
